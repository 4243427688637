import React from 'react'
import Layout from "../components/layout"
import stroll from '../images/stroll.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Hero: alive and, well, not dead" image={stroll} />
            <h1>
                Hero: alive and, well, not dead
            </h1>
            <img src={stroll} />
            <p>The early risers of Messina found themselves quite befuddled this morning when someone who looked very much like the (formerly) dead Hero was seen strolling arm-in-arm with Claudio.</p>
            <p>The not-at-all-ghostly couple sipped espressos from Caffè della Vita as they took in the morning air. But the real tea was spilled by the barista who served them when he identified Hero and confirmed that she appeared to be very much alive and well.</p>
            <p>Other sources flatly refuted baseless claims that this Hero was merely a lookalike cousin from another town. While we don’t yet have all the details of Hero’s once-called death and seeming resurrection, we do know that the young couple appear to be as in love as ever.</p>
            <p>Oh, to be alive and in love! Welcome back, Hero, from the great beyond, or Beatrice’s spare bedroom, whichever the case may be.</p>
        </Layout>
        //
    )
}
